import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import SEO from "../components/SEO";

import { makeStyles } from "@material-ui/core";
import { color, motion } from "framer-motion";
import Link from "../components/v2/Link";
import LazyImage from "../components/v4/LazyLoadImage/LazyImage";
import FaqSection from "../components/v4/FaqSection/FaqSection";

import whatsAppFeatureIcon from "../images/v4/homepage/icons/whatsAppFearureIcon.svg";
import benifitListIcon1 from "../images/v4/whatsappPage/icons/benifitListIcon1.svg";
import benifitListIcon2 from "../images/v4/whatsappPage/icons/benifitListIcon2.svg";
import benifitListIcon3 from "../images/v4/whatsappPage/icons/benifitListIcon3.svg";
import benifitListIcon4 from "../images/v4/whatsappPage/icons/benifitListIcon4.svg";

import benifitListIcon5 from "../images/v4/whatsappPage/icons/benifitListIcon5.svg";
import benifitListIcon6 from "../images/v4/whatsappPage/icons/benifitListIcon6.svg";
import benifitListIcon7 from "../images/v4/whatsappPage/icons/benifitListIcon7.svg";
import benifitListIcon8 from "../images/v4/whatsappPage/icons/benifitListIcon8.svg";

import benifitListIcon9 from "../images/v4/whatsappPage/icons/benifitListIcon9.svg";
import benifitListIcon10 from "../images/v4/whatsappPage/icons/benifitListIcon10.svg";
import benifitListIcon11 from "../images/v4/whatsappPage/icons/benifitListIcon11.svg";
import benifitListIcon12 from "../images/v4/whatsappPage/icons/benifitListIcon12.svg";

import testimonialCompanyLogo1 from "../images/v4/testimonials/testimonialCompanyLogo1.svg";
import spark1 from "../images/v4/whatsappPage/icons/spark1.svg";
import spark2 from "../images/v4/whatsappPage/icons/spark2.svg";
import spark3 from "../images/v4/whatsappPage/icons/spark3.svg";
import spark4 from "../images/v4/whatsappPage/icons/spark4.svg";
import whiteCheckCircle from "../images/v4/whatsappPage/icons/whiteCheckCircle.svg";
import successImage1 from "../images/v4/whatsappPage/images/successImage1.png";
import successImage2 from "../images/v4/whatsappPage/images/successImage2.png";
import successImage3 from "../images/v4/whatsappPage/images/successImage3.png";
import benifitImage1 from "../images/v4/whatsappPage/images/benifitImage1.png";
import benifitImage2 from "../images/v4/whatsappPage/images/benifitImage2.png";
import benifitImage3 from "../images/v4/whatsappPage/images/benifitImage3.png";
import benifitImage4 from "../images/v4/whatsappPage/images/benifitImage4.png";
import realWorldImage1 from "../images/v4/whatsappPage/images/realWorldImage1.png";
import realWorldImage2 from "../images/v4/whatsappPage/images/realWorldImage2.png";
import realWorldImage3 from "../images/v4/whatsappPage/images/realWorldImage3.png";
import effortsImg from "../images/v4/whatsappPage/images/effortsImg.png";
import whatsappPageHeroImg from "../images/v4/whatsappPage/images/whatsappPageHeroImg.png";

import broadcastImg1 from "../images/v4/whatsappPage/images/broadcastImg1.png";
import broadcastImg2 from "../images/v4/whatsappPage/images/broadcastImg2.png";
import broadcastImg3 from "../images/v4/whatsappPage/images/broadcastImg3.png";
import broadcastImg4 from "../images/v4/whatsappPage/images/broadcastImg4.png";

import successStoryCompany1 from "../images/v4/whatsappPage/images/successStoryCompany1.jpg";
import successStoryCompany2 from "../images/v4/whatsappPage/images/successStoryCompany2.jpg";
import successStoryCompany3 from "../images/v4/whatsappPage/images/successStoryCompany3.jpg";

import partnerIcon1 from "../images/v4/aiPage/icons/partnerIcon1.png";
import partnerIcon2 from "../images/v4/aiPage/icons/partnerIcon2.png";
import partnerIcon3 from "../images/v4/aiPage/icons/partnerIcon3.png";
import partnerIcon5 from "../images/v4/aiPage/icons/partnerIcon5.png";
import partnerIcon6 from "../images/v4/aiPage/icons/partnerIcon6.png";
import partnerIcon7 from "../images/v4/aiPage/icons/partnerIcon7.png";
import partnerIcon8 from "../images/v4/aiPage/icons/partnerIcon8.png";

const useStyles = makeStyles((theme) => ({
  whatsappSection: {
    background: "#015352",
    paddingTop: "50px",
    "& $aiSectionLeft": {
      width: "50%",
      gap: "25px",
    },
    "& .container": {
      paddingBottom: "40px",
    },

    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
      marginTop: "70px",
    },
    "@media only screen and (max-width: 600px)": {
      "& $aiSectionLeft": {
        width: "100%",
      },
      paddingTop: "0px",
      "& .centerContainer": {
        width: "100%",
        marginTop: "30px",
      },
      "& .container": {
        paddingBottom: "20px",
      },
    },
  },
  aiSectionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  aiSectionLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "40%",
    gap: "20px",
    "& .highlighterText": {
      background: "linear-gradient(90deg, #7AFFAB 0%, #80C8FF 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  aiSectionRight: {
    width: "40%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  integrationHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "30px",
    },
  },
  integrationLeft: {
    width: "42%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  integrationRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "40%",
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  benefitSection: {
    "& $integrationLeft": {
      width: "45%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "10px",
    },
    "& $integrationHeader": {
      justifyContent: "space-around",
      alignItems: "center",
    },
    "&.benefitSection0": {
      background: "#EFFFFB",
      "& $integrationHeader": {
        flexDirection: "row-reverse",
      },
    },
    "&.benefitSection1": {},
    "&.benefitSection2": {
      background: "#FFF9F0",
      "& $integrationHeader": {
        flexDirection: "row-reverse",
      },
    },
    "&.benefitSection3": {},
    "@media only screen and (max-width: 600px)": {
      "& $integrationLeft": {
        width: "100%",
      },
      "& $integrationRight": {
        width: "100%",
      },
      "&.benefitSection0": {
        "& $integrationHeader": {
          flexDirection: "column-reverse !important",
        },
      },
      "&.benefitSection2": {
        "& $integrationHeader": {
          flexDirection: "column !important",
        },
      },
    },
  },
  performanceSection: {
    background: "#1D0240",
    "& $integrationLeft": {
      width: "50%",
      gap: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "fle-start",
    },
    "@media only screen and (max-width: 600px)": {
      "& $integrationLeft": {
        width: "100%",
      },
    },
  },
  statsContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    rowGap: "25px",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "40px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "15px",
      marginTop: "20px",
      position: "relative",
    },
  },
  statsBox: {
    width: "32%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "50px",
    background: "#35175dc9",
    borderRadius: "15px",
    padding: "20px",
    minHeight: "230px",
    backdropFilter: "blur(30px) brightness(0.8)",
    "& p:nth-child(2)": {
      color: "#D1E4F1",
    },
    "& p:last-child": {
      color: "#D1E4F1",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "15px",
      padding: "15px",
      borderRadius: "20px",
      minHeight: "max-content",
    },
  },
  statsBoxBody: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "0",
    "& p:last-child": {
      marginTop: "5px",
    },
    "& .border": {
      border: "1px solid #FFFDFD40",
    },
    "& ul": {
      paddingLeft: "18px",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& ul": {
        paddingLeft: "18px",
      },
      "& li": {
        marginBottom: "10px",
      },
    },
  },
  statsFont: {
    fontSize: "48px",
    color: "#D3B0FF",
  },
  tabsSection: {
    background: "#FFF9F0",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  tabsContainer: {
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    border: "2px solid #DBDBDB",
    borderRadius: "40px",
    background: "#fff",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      padding: "5px",
    },
  },
  tab: {
    width: "33%",
    background: "",
    padding: "18px",
    background: "transparent",
    borderRadius: "40px",
    background: "transparent",
    transition: "0.3s ease",
    cursor: "pointer",
    "&.active": {
      color: "white",
      background:
        "linear-gradient(90deg, #FF682A 0%, #FF7056 35%, #FF682A 73%, #FF550F 100%)",
    },
    "@media only screen and (max-width: 600px)": {
      minWidth: "max-content",
      padding: "10px",
    },
  },
  tabCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 600px)": {
      marginTop: "30px",
      flexDirection: "column",
    },
  },
  tabCardLeftContainer: {
    display: "flex",
    gap: "40px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "@media only screen and (max-width: 600px)": {
      gap: "20px",
    },
  },
  benfitListContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "30px",
    marginTop: "50px",
    "@media only screen and (max-width: 600px)": {
      gap: "15px",
      marginTop: "20px",
    },
  },
  benfitListItem: {
    display: "flex",
    width: "100%",
    margin: "0 auto",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "15px",
    position: "relative",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "15px",
      alignItems: "flex-start",
    },
  },
  benfitListItemContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "10px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  benfitIcon: {
    width: "55px",
    "@media only screen and (max-width: 600px)": {
      width: "40px",
    },
  },
  moreFeatures: {
    "& .header": {
      display: "flex",
      width: "50%",
      gap: "10px",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      textAlign: "left",
    },
    "& .body": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginTop: "50px",
    },
    "@media only screen and (max-width: 600px)": {
      "& .header": {
        width: "100%",
      },
      "& .body": {
        flexDirection: "column",
        flexWrap: "wrap",
        rowGap: "15px",
      },
    },
    "@media only screen and (min-width: 1900px)": {
      "& .container": {
        paddingTop: "20px",
      },
    },
  },
  featureContainer: {
    width: "24%",
    gap: "16px",
    boxShadow: "0px 0px 16px #C1D7DB66",
    borderRadius: "16px",
    background: "#fff",
    padding: "16px",
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    "& p": {
      width: "80%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& p": {
        width: "100%",
      },
    },
  },
  commonPageFooterSection: {
    background:
      "transparent linear-gradient(90deg, #011D32 0%, #00182B 100%) 0% 0% no-repeat padding-box",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  featureIcon: {
    marginBottom: "15px",
  },
  successCard: {
    maxWidth: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0px 0px 20px #E3E2FD",
    "@media only screen and (max-width: 600px)": {
      maxWidth: "90%",
    },
  },
  successHeader: {
    maxWidth: "500px",
    width: "100%",
    height: "240px",
    padding: "16px",
    backgroundSize: "cover",
    backgroundPosition: "top",
    "@media only screen and (max-width: 600px)": {
      maxWidth: "100%",
      backgroundSize: "cover",
      backgroundPosition: "top",
    },
  },
  successBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: "500px",
    padding: "24px",
    gap: "16px",
    minHeight: "140px",
    "& img": {
      width: "150px",
    },
    "@media only screen and (max-width: 600px)": {
      maxWidth: "100%",
      minHeight: "140px",
      "& img": {
        width: "140px",
      },
    },
  },
  tag: {
    background: "#FFFFFF33",
    backdropFilter: "blur(30px) brightness(0.8)",
    borderRadius: "50px",
    padding: "10px 16px",
    width: "max-content",
  },
  successStorySlider: {
    padding: "30px 120px  !important",
    "@media only screen and (max-width: 600px)": {
      padding: "30px 15px  !important",
    },
  },
  successSection: {
    "& .centerContainer": {
      textAlign: "center",
    },
  },
  partnerSlide: {
    width: "144px !important",
    "& img": {
      width: "144px",
    },
    "@media only screen and (max-width: 600px)": {
      width: "max-content !important",
      "& img": {
        width: "100px",
      },
      // marginRight: "20px !important",
    },
  },
  partnerSlider: {
    padding: "10px 0 60px !important",
    "@media only screen and (max-width: 600px)": {
      paddingBottom: "40px !important",
    },
  },
  integrationRightImage: {
    "& img": {
      width: "100%",
    },
  },
  performanceImage: {
    position: "absolute",
    zIndex: "0",
    "& img": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      position: "relative",
    },
  },
  broadcastContainer: {
    background: "#EFFFFB",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "60%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  broadcastContainerInnerBox: {
    width: "100%",
    padding: "20px",
    "&:last-child": {
      background: "#fff",
      "& $broadcastPointer::before": {
        display: "none",
      },
    },
  },
  broadcastContainerInner: {
    gap: "30px",
    width: "100%",
    display: "flex",
    // padding: "0 8rem 1rem 8rem",
    padding: "0 0rem 1rem 0rem",
    alignItems: "stretch",
    justifyContent: "flex-start",
    // cursor: "grab",
    // "&::-webkit-scrollbar-track": {
    //   "-webkit-box-shadow": "none",
    //   backgroundColor: "#EDF1F6",
    // },

    // "&::-webkit-scrollbar": {
    //   width: "5px",
    //   height: "5px",
    //   borderRadius: "50%",
    //   backgroundColor: "#EDF1F6",
    // },

    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#D2DBE4",
    //   borderRadius: "50px",
    // },
    "@media only screen and (max-width: 600px)": {
      padding: "0 15px",
      paddingBottom: "10px",
    },
    "@media only screen and (min-width: 1900px)": {},
  },
  broadcastRightContainer: {
    position: "absolute",
    top: "0",
    left: "0",
  },
  broadcastBox: {
    gap: "20px",
    display: "flex",
    padding: "32px",
    background: "#009249",
    alignItems: "flex-start",
    borderRadius: "20px",
    flexDirection: "column",
    justifyContent: "space-between",
    // maxWidth:'450px',
    paddingBottom: "0",
    "@media only screen and (max-width: 600px)": {
      padding: "15px",
      maxWidth: "90%",
      paddingBottom: "0",
      gap: "15px",
    },
  },
  broadcastBoxInnerContainer: {
    gap: "40px",
    width: "90%",
    margin: "0 auto",
    display: "flex",
    position: "relative",
    maxWidth: "1200px",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  broadcastOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
  },
  broadcastText: {
    color: "#B9E0E0",
  },
  broadcastPointer: {
    color: "#fff",
    border: "1px solid #fff",
    borderRadius: "50%",
    padding: "5px 11px",
    background: "transparent",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "100%",
      left: "50%",
      height: "140%",
      borderRight: "1px dotted",
    },
  },
  cardPointer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "@media only screen and (max-width: 600px)": {
      alignItems: "baseline",
    },
  },
  broadcastImg: {
    width: "100%",
    position: "relative",
    bottom: "0",
  },
  scrollPrompt: {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    background: "rgba(0, 0, 0, 0.7)",
    padding: "10px 20px",
    borderRadius: "8px",
  },
  wpFaq: {
    "@media only screen and (max-width: 600px)": {
      "& section": {
        padding: "0px 0 0 !important",
      },
    },
  },
  belowText: {
    width: "100%",
    textAlign: "right",
    color: "#9279B1",
    marginTop: "10px",
  },
  pointerImg: {
    "& img": {
      width: "28px",
    },
    "@media only screen and (max-width: 600px)": {
      "& img": {
        width: "10px",
      },
    },
  },
  cardHeader: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    gap: "5px",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    gap: "20px",
    flexDirection: "column",
    "@media only screen and (max-width: 600px)": {
      gap: "10px",
    },
  },
}));

const WhatsAppNew = () => {
  const classes = useStyles();

  const [activeRealWorld, setActiveRealWorld] = useState(0);
  const [activeBroadcast, setActiveBroadcast] = useState(0);
  const [isLastPointViewed, setIsLastPointViewed] = useState(false);

  const benifitList = [
    {
      tag: "WhatsApp Flows",
      img: benifitImage1,
      title: "Auto-capture leads with WhatsApp flows",
      desc: "Auto-capture leads, ease appointment bookings, streamline feedback collection and more with interactive WhatsApp forms. Create end-to-end personalised user journeys and automate sales and support seamlessly within WhatsApp CRM.",
    },
    {
      tag: "Catalog",
      img: benifitImage2,
      title: "Convert browsers to buyers with WhatsApp catalogs",
      desc: "Create an end-to-end shopping experience for your customers with WhatsApp catalogs. Effortlessly showcase your products with specifications, images, and pricing, and enable your customers to purchase instantly through WhatsApp chat.",
    },
    {
      tag: "Click-to-WhatsApp ads",
      img: benifitImage3,
      title: "Track and convert leads from WhatsApp ads",
      desc: "Capture and track leads automatically from Facebook and Instagram using Click-to-WhatsApp ads. Engage prospects with real-time conversations, address their queries, and build strong relationships to seamlessly convert them into loyal customers.",
    },
    {
      tag: "Broadcast",
      img: benifitImage4,
      title: "Drive sales at scale with WhatsApp broadcast",
      desc: "Launch marketing campaigns at a scale within WhatsApp CRM. Send promotional messages, product updates, seasonal greetings, and more to multiple contacts at once. Drive higher engagement and scale your outreach effortlessly.",
    },
  ];
  const statsBoxContent = [
    {
      heading: "Enhanced customer engagement",
      number: "50%",
      data: "Connect with customers on their preferred platform to boost engagement and build stronger relationships.",
    },
    {
      heading: "Reduced response time",
      number: "70%",
      data: "Automate instant replies with message templates, reducing response times significantly.",
    },
    {
      heading: "Improved customer satisfaction",
      number: "2x",
      data: "Send instant and personalised responses in real-time to boost customer satisfaction.",
    },
    {
      heading: "Increased sales conversions",
      number: "60%",
      data: "Engage and send in-chat product catalogs to guide customers through the sales funnel.",
    },
    {
      heading: "Better data insights",
      number: "3x",
      data: "Get valuable customer insights through detailed WhatsApp communication analysis.",
    },
    {
      heading: "Reduced marketing cost",
      number: "30%",
      data: "Run high-impact WhatsApp campaigns without additional platforms or teams.",
    },
  ];
  const tabContentData = [
    {
      tabContent: [
        {
          icon: benifitListIcon1,
          heading: "In-chat product catalogs",
          content:
            "Allow customers to browse and purchase products without leaving WhatsApp.",
          alt: "All-in-one CRM",
        },
        {
          icon: benifitListIcon2,
          heading: "One-click reordering",
          content:
            "Enable reordering products with a single message on WhatsApp.",
          alt: "Omnichannel CRM",
        },
        {
          icon: benifitListIcon3,
          heading: "Abandoned cart recovery",
          content:
            "Send reminders and incentives to complete purchases from their cart.",
          alt: "Sales management",
        },
        {
          icon: benifitListIcon4,
          heading: "Personalised shopping assistance",
          content:
            "Provide tailored recommendations and responses to queries in real-time.",
          alt: "Activity management",
        },
      ],
      image: realWorldImage1,
      alt: "Whatsapp CRM for eCommerce",
      subtitle:
        "Enhance customer engagement, simplify purchasing, and recover lost sales with WhatsApp integration in CRM.",
    },
    {
      tabContent: [
        {
          icon: benifitListIcon5,
          heading: "Appointment management",
          content:
            "Send appointment reminders and allow easy rescheduling through WhatsApp.",
          alt: "All-in-one CRM",
        },
        {
          icon: benifitListIcon6,
          heading: "Telemedicine consultations",
          content:
            "Conduct video consultations and send follow-ups via WhatsApp.",
          alt: "Omnichannel CRM",
        },
        {
          icon: benifitListIcon7,
          heading: "Medication adherence support",
          content: "Send personalised reminders and health tips to patients.",
          alt: "Sales management",
        },
        {
          icon: benifitListIcon8,
          heading: "Secure result sharing",
          content:
            "Deliver test results directly and securely via encrypted messages.",
          alt: "Activity management",
        },
      ],
      image: realWorldImage2,
      alt: "Whatsapp CRM for Healthcare",
      subtitle:
        "Elevate patient engagement, streamline appointments, and improve health outcomes with WhatsApp CRM integration.",
    },
    {
      tabContent: [
        {
          icon: benifitListIcon9,
          heading: "Course information sharing",
          content:
            "Provide details on courses, requirements, and application processes instantly.",
          alt: "All-in-one CRM",
        },
        {
          icon: benifitListIcon10,
          heading: "Virtual office hours",
          content:
            "Allow students to book one-on-one sessions with instructors via WhatsApp.",
          alt: "Omnichannel CRM",
        },
        {
          icon: benifitListIcon11,
          heading: "Assignment distribution",
          content:
            "Send study materials and assignment details directly to students' phones.",
          alt: "Sales management",
        },
        {
          icon: benifitListIcon12,
          heading: "Exam notifications",
          content:
            "Send reminders about upcoming exams and share results securely.",
          alt: "Activity management",
        },
      ],
      image: realWorldImage3,
      alt: "Whatsapp CRM for Education",
      subtitle:
        "Engage students effortlessly, organise administration, and improve learning outcomes with a WhatsApp-enabled CRM.",
    },
  ];
  const featureList = [
    {
      icon: spark4,
      heading: "Auto-lead capture",
      text: "Capture and convert leads automatically from website, messages, WhatsApp ads, and more.",
    },
    {
      icon: spark3,
      heading: "Message templates",
      text: "Reply quickly with ready-to-use message templates featuring media and interactive buttons.",
    },
    {
      icon: spark2,
      heading: "WhatsApp groups",
      text: "Collaborate and resolve issues quickly with WhatsApp group chats within CRM.",
    },
    {
      icon: spark1,
      heading: "AssistAI chatbot",
      text: "Resolve customer queries 24/7 with automated support from AI chatbot, AssistAI.",
    },
  ];
  const faqs = [
    {
      question: "What is WhatsApp CRM?",
      answer: (
        <>
          WhatsApp CRM is a powerful tool that combines the capabilities of
          WhatsApp with a CRM system. <br /> <br /> With Pepper Cloud, a
          WhatsApp CRM tool, businesses can efficiently manage leads, send
          instant responses using message templates, schedule broadcasts,
          deliver interactive messages, broadcast marketing campaigns, and do
          much more.
        </>
      ),
    },
    {
      question: "How long does it take to set up WhatsApp for my business?",
      answer:
        "If you have all the prerequisites ready, you can start using WhatsApp through the CRM system within a few hours.",
    },
    {
      question:
        "Does Pepper Cloud charge for WhatsApp messages sent through the CRM?",
      answer: (
        <>
          No, Pepper Cloud doesn’t charge for the messages. However, you may
          incur charges from WhatsApp. For more details, check out{" "}
          <a
            target="_blank"
            href="https://blog.peppercloud.com/whatsapp-api-pricing-everything-you-need-to-know/?utm_source=Website&utm_medium=WhatsAppCRM"
          >
            WhatsApp pricing
          </a>
          .
        </>
      ),
    },
    {
      question:
        "How many WhatsApp accounts can be integrated with Pepper Cloud CRM?",
      answer:
        "You can integrate an unlimited number of WhatsApp accounts with Pepper Cloud CRM.",
    },
    {
      question: "Why should businesses use WhatsApp for sales?",
      answer:
        "WhatsApp, with its 2 billion active users, is not merely a personal chat platform but is increasingly becoming a pivotal touchpoint for businesses to engage with their customers.",
    },
    {
      question: "Why is Pepper Cloud the best WhatsApp CRM software?",
      answer: (
        <>
          Pepper Cloud stands out as the best WhatsApp CRM software, and here
          are a few reasons why:
          <ul>
            <li>
              It offers a centralised communication hub for sales, marketing,
              and support.
            </li>
            <li>
              It enables advanced WhatsApp marketing, automation, and real-time
              analytics.
            </li>
            <li>
              It helps in accelerating sales cycles with seamless integration.
            </li>
            <li>
              It enables collaborative client acquisition with multi-user
              access.
            </li>
            <li>It offers easily accessible 24/7 customer support.</li>
          </ul>
        </>
      ),
    },
    {
      question:
        "How does Pepper Cloud CRM integration with WhatsApp help improve marketing campaigns?",
      answer:
        "Pepper Cloud enables you to create, launch, and track targeted WhatsApp marketing campaigns directly within CRM. With customisable templates, advanced automation, and real-time analytics, you can easily engage your audience, boost conversions, and track campaign performance.",
    },
    {
      question: "Can WhatsApp CRM help shorten the sales cycle?",
      answer:
        "Yes, Pepper Cloud streamlines your sales process from lead generation to deal closure, significantly reducing the sales cycle. With real-time communication via WhatsApp, automated workflows, and integrated sales pipeline management, your team can quickly capture leads, engage prospects, and close deals faster, all while ensuring a personalised experience for each customer.",
    },
    {
      question:
        "How does Pepper Cloud improve customer support through WhatsApp?",
      answer:
        "Pepper Cloud helps deliver superior customer support by enabling your team to manage and respond to WhatsApp conversations efficiently. With AI-powered chatbots to handle routine queries and the ability to provide personalised responses, your team can resolve customer issues quickly and effectively, leading to higher customer satisfaction and retention.",
    },
    {
      question:
        "Does Pepper Cloud provide real-time data synchronisation with WhatsApp?",
      answer:
        "Yes, Pepper Cloud ensures real-time data synchronisation between WhatsApp and your CRM. This feature ensures that your team always has the latest customer information at their fingertips, enabling quick and informed decision-making during customer interactions.",
    },
    {
      question: "Can I automate WhatsApp communications in Pepper Cloud CRM?",
      answer:
        "Absolutely! Pepper Cloud offers customisable automation features, allowing you to set up automated workflows for routine WhatsApp communications. This frees up your team to focus on high-value tasks while ensuring timely and consistent customer interactions.",
    },
    {
      question: "Is Pepper Cloud WhatsApp CRM accessible on multiple devices?",
      answer: (
        <>
          Yes, Pepper Cloud CRM is fully accessible on multiple devices,
          enabling all your agents to access the CRM software one go.{" "}
          <a
            href="https://peppercloud.com/best-mobile-crm-system-app-ios-android"
            target="_blank"
          >
            Pepper Cloud mobile app
          </a>{" "}
          enables you to stay connected from anywhere, anytime.
        </>
      ),
    },
    {
      question: "What are the best WhatsApp CRM software options?",
      answer: (
        <>
          Here are some top WhatsApp CRM solutions:
          <ul>
            <li>
              <strong>Pepper Cloud:</strong> Best for multi-channel integration,
              advanced workflow automation, and AI-powered features.
            </li>
            <li>
              <strong>DoubleTick:</strong> Suitable for mass messaging and basic
              lead management with WhatsApp.
            </li>
            <li>
              <strong>TeleCRM:</strong> Mobile-first CRM with WhatsApp
              integration, ideal for field sales teams.
            </li>
            <li>
              <strong>Kommo CRM:</strong> Comprehensive CRM with WhatsApp
              integration for tracking deals and tasks.
            </li>
            <li>
              <strong>Interakt:</strong> Focuses on WhatsApp Business API
              integration and basic automated responses for customer
              communication.
            </li>
          </ul>
        </>
      ),
    },
    {
      question:
        "What should SMBs consider when choosing the best WhatsApp CRM?",
      answer: (
        <>
          Before choosing a WhatsApp-enabled CRM for their business, SMBs like
          you should consider the following points:
          <ul>
            <li>
              Evaluate your company's specific requirements to identify key
              features needed in a WhatsApp CRM.
            </li>
            <li>
              Look for software with an intuitive, user-friendly interface to
              ensure quick adoption and enhanced productivity.
            </li>
            <li>
              Choose a CRM that seamlessly integrates with your current tech
              stack for consistent data and streamlined workflows.
            </li>
            <li>
              Choose a WhatsApp CRM that can adapt to your growing business
              needs.
            </li>
            <li>
              Assess both upfront and long-term costs against potential
              improvements in efficiency and sales.
            </li>
            <li>
              Ensure the CRM offers strong data protection measures and complies
              with relevant regulations.
            </li>
          </ul>
        </>
      ),
    },
  ];
  const successList = [
    {
      tag: "Travel and tourism",
      readMore: "/crm-case-studies/dreamcation/",
      content:
        "Dreamcation is slashing response time with Pepper Cloud CRM system",
      companyLogo: successStoryCompany1,
      backgroundImage: successImage2,
      alt: "Pepper Cloud CRM + Dreamcation",
    },
    {
      tag: "Cleaning services",
      readMore: "/crm-case-studies/eng-choon-enterprise/",
      content:
        "From missed leads to faster conversions: Eng Choon Enterprise success story",
      companyLogo: successStoryCompany2,
      backgroundImage: successImage1,
      alt: "Pepper Cloud CRM + Eng Choon Enterprise",
    },
    {
      tag: "Recruitment agency",
      readMore: "/crm-case-studies/the-supreme/",
      content:
        "From lagging leads to leading the pack: Supreme HR's Success with Pepper Cloud",
      companyLogo: successStoryCompany3,
      backgroundImage: successImage3,
      alt: "Pepper Cloud CRM + The Supreme HR Advisory",
    },
    {
      tag: "Travel and tourism",
      readMore: "/crm-case-studies/dreamcation/",
      content:
        "Dreamcation is slashing response time with Pepper Cloud CRM system",
      companyLogo: successStoryCompany1,
      backgroundImage: successImage2,
      alt: "Pepper Cloud CRM + Dreamcation",
    },
    {
      tag: "Cleaning services",
      readMore: "/crm-case-studies/eng-choon-enterprise/",
      content:
        "From missed leads to faster conversions: Eng Choon Enterprise success story",
      companyLogo: successStoryCompany2,
      backgroundImage: successImage1,
      alt: "Pepper Cloud CRM + Eng Choon Enterprise",
    },
    {
      tag: "Recruitment agency",
      readMore: "/crm-case-studies/the-supreme/",
      content:
        "From lagging leads to leading the pack: Supreme HR's Success with Pepper Cloud",
      companyLogo: successStoryCompany3,
      backgroundImage: successImage3,
      alt: "Pepper Cloud CRM + The Supreme HR Advisory",
    },
  ];
  const partners = [
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
  ];
  const broadcastList = [
    {
      pointer: "Target the right audience",
      card: {
        pointer: [
          "Categorise customers by demographics, behaviour, and interests.",
          "Label each group with colourful tags (Ex: VIP customers).",
        ],
        img: broadcastImg1,
        alt: "Create whatsapp broadcast list",
        backgroundColor: "hsla(163,100%,18%,1)",
        backgroundImage:
          "radial-gradient(at 98% 100%, hsla(158,100%,33%,1) 0px, transparent 50%),radial-gradient(at 7% 93%, hsla(176,100%,26%,1) 0px, transparent 50%),radial-gradient(at 0% 0%, hsla(160,100%,33%,1) 0px, transparent 50%),radial-gradient(at 100% 5%, hsla(195,93%,40%,1) 0px, transparent 50%),radial-gradient(at 30% 100%, hsla(242,100%,70%,1) 0px, transparent 50%)",
      },
    },
    {
      pointer: "Craft messages that sell",
      card: {
        pointer: [
          "Craft compelling personalised messages for each audience segment.",
          "Add media files, emojis, and interactive buttons to boost engagement.",
        ],
        img: broadcastImg2,
        alt: "Create whatsapp broadcast message​",
        backgroundColor: "hsla(205,98%,28%,1)",
        backgroundImage:
          "radial-gradient(at 97% 96%, hsla(166,100%,27%,1) 0px, transparent 50%),radial-gradient(at 5% 10%, hsla(176,97%,35%,1) 0px, transparent 50%),radial-gradient(at 96% 3%, hsla(195,100%,45%,1) 0px, transparent 50%),radial-gradient(at 15% 100%, hsla(242,100%,70%,1) 0px, transparent 50%)",
      },
    },
    {
      pointer: "Schedule and send",
      card: {
        pointer: [
          "Analyse the optimal send times based on your customers' activity patterns.",
          "Schedule broadcasts in advance or send promotions instantly.",
        ],
        img: broadcastImg3,
        alt: "Create whatsapp broadcast message​",
        backgroundColor: "hsla(268,60%,40%,1)",
        backgroundImage:
          "radial-gradient(at 98% 97%, hsla(260,61%,51%,1) 0px, transparent 50%),radial-gradient(at 5% 10%, hsla(235,60%,54%,1) 0px, transparent 50%),radial-gradient(at 96% 3%, hsla(210,82%,50%,1) 0px, transparent 50%),radial-gradient(at 15% 100%, hsla(242,100%,70%,1) 0px, transparent 50%)",
      },
    },
    {
      pointer: "Monitor, refine, and repeat",
      card: {
        pointer: [
          "Track campaign performance with real-time insights.",
          "Review open rates, responses, and engagement to optimise future campaigns.",
        ],
        img: broadcastImg4,
        alt: "CRM for Whatsapp broadcast",
        backgroundColor: "hsla(180,100%,23%,1)",
        backgroundImage:
          "radial-gradient(at 52% 100%, hsla(197,59%,53%,1) 0px, transparent 50%),radial-gradient(at 96% 3%, hsla(210,82%,50%,1) 0px, transparent 50%),radial-gradient(at 15% 100%, hsla(242,100%,70%,1) 0px, transparent 50%)",
      },
    },
  ];

  return (
    <main>
      <SEO
        title={
          "WhatsApp CRM | Best CRM with Whatsapp Integration | Pepper Cloud"
        }
        pathname={"https://peppercloud.com/whatsapp-crm"}
        keywords="crm with whatsapp integration, crm whatsapp integration, whatsapp integration, WhatsApp-enabled CRM"
        description={
          " WhatsApp CRM to broadcast WhatsApp sales and marketing campaigns, swiftly respond to customers with AI chatbots, automation and pre-build templates, share catalogs and more"
        }
        ogData={{
          "og:image": [
            "/meta/og/1X1/home.png",
            "/meta/og/1024X512/home.png",
            "/meta/og/1200X630/home.png",
          ],
        }}
        jsonLd={[
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Pepper Cloud",
            legalName: "Pepper Cloud PTE LTD",
            url: "https://peppercloud.com/",
            logo: "https://peppercloud.com/icons/logo.png",
            foundingDate: "2017",
            address: [
              {
                "@type": "PostalAddress",
                streetAddress: "#04-01/02",
                addressLocality: "Blk 79 Ayer Rajah Crescent",
                addressRegion: "SG",
                postalCode: "139955",
                addressCountry: "Singapore",
              },
              {
                "@type": "PostalAddress",
                streetAddress:
                  "2nd Floor, Salarpuria Tower 1, 7th Block Koramangala",
                addressLocality: "Bengaluru",
                addressRegion: "KA",
                postalCode: "560095",
                addressCountry: "India",
              },
            ],
            contactPoint: {
              "@type": "ContactPoint",
              contactType: "customer support",
              telephone: "+6566562616",
              email: ["sales@peppercloud.com", "support@peppercloud.com"],
            },
            sameAs: [
              "https://twitter.com/peppercloudpte",
              "https://www.linkedin.com/company/peppercloud/",
              "https://www.facebook.com/PepperCloudPteLtd",
              "https://www.instagram.com/pepper.cloud/",
            ],
          },
          {
            "@context": "https://schema.org/",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "What is WhatsApp CRM?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "WhatsApp CRM is a powerful tool that combines the capabilities of WhatsApp with a CRM system.  With Pepper Cloud, a WhatsApp CRM tool, businesses can efficiently manage leads, send instant responses using message templates, schedule broadcasts, deliver interactive messages, broadcast marketing campaigns, and do much more.",
                },
              },
              {
                "@type": "Question",
                name: "How long does it take to set up WhatsApp for my business?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "If you have all the prerequisites ready, you can start using WhatsApp through the CRM system within a few hours.",
                },
              },
              {
                "@type": "Question",
                name: "Does Pepper Cloud charge for WhatsApp messages sent through the CRM?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "No, Pepper Cloud doesn’t charge for the messages. However, you may incur charges from WhatsApp. For more details, check out WhatsApp pricing.",
                },
              },
              {
                "@type": "Question",
                name: "How many WhatsApp accounts can be integrated with Pepper Cloud CRM?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "You can integrate an unlimited number of WhatsApp accounts with Pepper Cloud CRM.",
                },
              },
              {
                "@type": "Question",
                name: "Why should businesses use WhatsApp for sales?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "WhatsApp, with its 2 billion active users, is not merely a personal chat platform but is increasingly becoming a pivotal touchpoint for businesses to engage with their customers.",
                },
              },
              {
                "@type": "Question",
                name: "Why is Pepper Cloud the best WhatsApp CRM software?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Pepper Cloud stands out as the best WhatsApp CRM software, and here are a few reasons why: It offers a centralised communication hub for sales, marketing, and support. It enables advanced WhatsApp marketing, automation, and real-time analytics. It helps in accelerating sales cycles with seamless integration. It enables collaborative client acquisition with multi-user access. It offers easily accessible 24/7 customer support.",
                },
              },
              {
                "@type": "Question",
                name: "How does Pepper Cloud CRM integration with WhatsApp help improve marketing campaigns?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Pepper Cloud enables you to create, launch, and track targeted WhatsApp marketing campaigns directly within CRM. With customisable templates, advanced automation, and real-time analytics, you can easily engage your audience, boost conversions, and track campaign performance.",
                },
              },
              {
                "@type": "Question",
                name: "Can WhatsApp CRM help shorten the sales cycle?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, Pepper Cloud streamlines your sales process from lead generation to deal closure, significantly reducing the sales cycle. With real-time communication via WhatsApp, automated workflows, and integrated sales pipeline management, your team can quickly capture leads, engage prospects, and close deals faster, all while ensuring a personalised experience for each customer.",
                },
              },
              {
                "@type": "Question",
                name: "How does Pepper Cloud improve customer support through WhatsApp?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Pepper Cloud helps deliver superior customer support by enabling your team to manage and respond to WhatsApp conversations efficiently. With AI-powered chatbots to handle routine queries and the ability to provide personalised responses, your team can resolve customer issues quickly and effectively, leading to higher customer satisfaction and retention.",
                },
              },
              {
                "@type": "Question",
                name: "Does Pepper Cloud provide real-time data synchronisation with WhatsApp?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, Pepper Cloud ensures real-time data synchronisation between WhatsApp and your CRM. This feature ensures that your team always has the latest customer information at their fingertips, enabling quick and informed decision-making during customer interactions.",
                },
              },
              {
                "@type": "Question",
                name: "Can I automate WhatsApp communications in Pepper Cloud CRM?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Absolutely! Pepper Cloud offers customisable automation features, allowing you to set up automated workflows for routine WhatsApp communications. This frees up your team to focus on high-value tasks while ensuring timely and consistent customer interactions.",
                },
              },
              {
                "@type": "Question",
                name: "Is Pepper Cloud WhatsApp CRM accessible on multiple devices?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, Pepper Cloud CRM is fully accessible on multiple devices, enabling all your agents to access the CRM software one go. Pepper Cloud mobile app enables you to stay connected from anywhere, anytime.",
                },
              },
              {
                "@type": "Question",
                name: "What are the best WhatsApp CRM software options?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Here are some top WhatsApp CRM solutions: Pepper Cloud: Best for multi-channel integration, advanced workflow automation, and AI-powered features. DoubleTick: Suitable for mass messaging and basic lead management with WhatsApp. TeleCRM: Mobile-first CRM with WhatsApp integration, ideal for field sales teams. Kommo CRM: Comprehensive CRM with WhatsApp integration for tracking deals and tasks. Interakt: Focuses on WhatsApp Business API integration and basic automated responses for customer communication.",
                },
              },
              {
                "@type": "Question",
                name: "What should SMBs consider when choosing the best WhatsApp CRM?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Before choosing a WhatsApp-enabled CRM for their business, SMBs like you should consider the following points: Evaluate your company's specific requirements to identify key features needed in a WhatsApp CRM. Look for software with an intuitive, user-friendly interface to ensure quick adoption and enhanced productivity. Choose a CRM that seamlessly integrates with your current tech stack for consistent data and streamlined workflows. Choose a WhatsApp CRM that can adapt to your growing business needs. Assess both upfront and long-term costs against potential improvements in efficiency and sales. Ensure the CRM offers strong data protection measures and complies with relevant regulations.",
                },
              },
            ],
          },
        ]}
      />
      <section className={classes.whatsappSection}>
        <div className="container">
          <div className={classes.aiSectionContainer}>
            <div className={classes.aiSectionLeft}>
              <motion.div
                className="sectionTag"
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <LazyImage
                  source={whatsAppFeatureIcon}
                  alt="WhatsApp CRM integration"
                />
                <p className="font-18 font-white font-wix-semibold">
                  WhatsApp integration
                </p>
              </motion.div>
              <motion.h1
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-72 font-white"
              >
                Turn chats into sales with{" "}
                <span className="highlighterText">WhatsApp CRM</span>
              </motion.h1>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18 font-white"
              >
                Run WhatsApp marketing campaigns, engage in instant and
                personalised conversations, and boost your sales with the power
                of AI-powered WhatsApp CRM.
              </motion.p>
              <div className="buttonContaienr">
                {/* <Link
                  to={
                    "/whatsapp-crm?utm_source=Homepage&utm_medium=CTA&utm_content=Explore+WhatApp+CRM"
                  }
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="transparentButton font-wix-medium font-16"
                >
                  Explore WhatApp CRM
                </Link> */}
                <Link
                  to={
                    "/contact?utm_source=WhatsApp+CRM&utm_medium=CTA&utm_content=+Try+WhatsAppCRM"
                  }
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className=" primaryButton font-wix-medium font-16 font-white"
                >
                  Try WhatsApp CRM
                </Link>
              </div>
            </div>
            <motion.div
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className={classes.aiSectionRight}
            >
              <LazyImage
                source={whatsappPageHeroImg}
                width={"100%"}
                alt={"WhatsApp CRM"}
              />
            </motion.div>
          </div>
          <div className="centerContainer">
            <motion.h3
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-24 font-white"
            >
              Best CRM with WhatsApp integration - Trusted by SMEs globally
            </motion.h3>
          </div>
        </div>
        <Swiper
          className={classes.partnerSlider}
          slidesPerView="auto"
          spaceBetween={60}
          loop={true}
          speed={2000}
          modules={[Autoplay]}
          allowTouchMove={false}
          autoplay={{
            delay: 100,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          }}
        >
          {partners.map((partner) => (
            <SwiperSlide className={classes.partnerSlide}>
              <img src={partner.icon} alt={partner.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      {benifitList.map((benifit, index) => (
        <section
          className={clsx(classes.benefitSection, "benefitSection" + index)}
        >
          <div className="container">
            <div className={classes.integrationHeader}>
              <div className={classes.integrationLeft}>
                <motion.div
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="sectionTag"
                >
                  <p className="font-18  font-wix-semibold color-blue1">
                    {benifit.tag}
                  </p>
                </motion.div>
                <motion.h2
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="font-wix-semibold font-56 color-black1"
                >
                  {benifit.title}
                </motion.h2>
                <motion.p
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="font-wix-regular font-18 color-black2 mt20"
                >
                  {benifit.desc}
                </motion.p>
              </div>
              <div className={classes.integrationRight}>
                <LazyImage
                  source={benifit.img}
                  alt={benifit.title}
                  className={classes.integrationRightImage}
                />
              </div>
            </div>
          </div>
        </section>
      ))}
      <section className={classes.broadcastContainer}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 color-black1"
            >
              How to send broadcasts on WhatsApp CRM?
            </motion.h2>
          </div>
        </div>
        <Swiper
          slidesPerView={1.9}
          // centeredSlides={true}
          spaceBetween={30}
          grabCursor={true}
          loop={false}
          speed={5000}
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          // freeMode={true}
          className={classes.broadcastContainerInner}
          breakpoints={{
            // For smaller screens (mobile)
            0: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              freeMode: true,
              grabCursor: false,
              allowTouchMove: true,
              spaceBetween: 10,
            },
            // For larger screens (desktop)
            768: {
              slidesPerView: 1.9,
              centeredSlides: true,
              spaceBetween: 30,
              grabCursor: true,
            },
          }}
        >
          {broadcastList.map((point, index) => (
            <SwiperSlide
              className={classes.broadcastBox}
              style={{
                backgroundColor: point.card.backgroundColor,
                backgroundImage: point.card.backgroundImage,
              }}
            >
              <div className={classes.header}>
                <div className={classes.cardHeader}>
                  <p className="font-42 font-wix-semibold font-white">
                    Step {index + 1}
                  </p>
                  <p className="font-32 font-wix-medium font-white">
                    {point.pointer}
                  </p>
                </div>
                {point.card.pointer.map((content) => (
                  <div className={classes.cardPointer}>
                    <LazyImage
                      source={whiteCheckCircle}
                      alt={"pointer"}
                      className={classes.pointerImg}
                    />
                    <p className="font-wix-regular font-18 font-white">
                      {content}
                    </p>
                  </div>
                ))}
              </div>
              <img
                src={point.card.img}
                alt={point.card.alt}
                className={classes.broadcastImg}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <div className={classes.broadcastContainerInner}>
          {broadcastList.map((point, index) => (
            <div
              className={classes.broadcastBox}
              style={{
                backgroundColor: point.card.backgroundColor,
                backgroundImage: point.card.backgroundImage,
              }}
            >
              <div className={classes.header}>
                <div className={classes.cardHeader}>
                  <p className="font-42 font-wix-semibold font-white">
                    Step {index + 1}
                  </p>
                  <p className="font-32 font-wix-medium font-white">
                    {point.pointer}
                  </p>
                </div>
                {point.card.pointer.map((content) => (
                  <div className={classes.cardPointer}>
                    <LazyImage
                      source={whiteCheckCircle}
                      alt={"pointer"}
                      className={classes.pointerImg}
                    />
                    <p className="font-wix-regular font-18 font-white">
                      {content}
                    </p>
                  </div>
                ))}
              </div>
              <LazyImage
                source={point.card.img}
                alt={point.card.alt}
                className={classes.broadcastImg}
              />
            </div>
          ))}
        </div> */}
      </section>
      <section className={classes.moreFeatures}>
        <div className="container">
          <div className="header">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 color-black1"
            >
              More features
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 color-black1"
            >
              Empower your sales and marketing teams to speed up deal closure,
              reduce response times, streamline workflows, and boost sales with
              powerful features.
            </motion.p>
          </div>
          <div className="body">
            {featureList.map((feature) => (
              <div className={classes.featureContainer}>
                <LazyImage
                  source={feature.icon}
                  alt={feature.heading}
                  className={classes.featureIcon}
                  // alt={listItem.alt}
                />
                <p className="font-wix-semibold font-18 color-black1">
                  {feature.heading}
                </p>
                <p className="font-wix-mediu font-18 color-black1">
                  {feature.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className={classes.performanceSection}>
        <div className="container">
          <div className={classes.integrationHeader}>
            <div className={classes.integrationLeft}>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-medium font-56 color-black7"
              >
                Maximise sales, minimise efforts: WhatsApp CRM benefits
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18 color-purple3"
              >
                Turn chats into closed deals with the power of
                WhatsApp-integrated CRM. Streamline your communications, enhance
                customer relationships, and keep your team in sync through a
                single, customer-focused platform.
              </motion.p>
            </div>
            <div className={classes.integrationRight}>
              <LazyImage
                source={effortsImg}
                alt={"WhatsApp CRM benefits"}
                className={classes.performanceImage}
              />
            </div>
          </div>
          <div className={classes.statsContainer}>
            {statsBoxContent.map((stat, index) => (
              <motion.div
                whileInView={{ opacity: [0, 1], y: [50, 0] }}
                transition={{ duration: 1, delay: index * 0.1 }}
                className={classes.statsBox}
              >
                {/* <div className={classes.statsBoxHeader}>
                  <p className="font-18 color-purple3 font-wix-regular">
                    {stat.heading}
                  </p>
                </div> */}
                <div className={classes.statsBoxBody}>
                  <p
                    className={clsx(
                      classes.statsFont,
                      "font-wix-medium",
                      "color-white"
                    )}
                  >
                    {stat.number}
                  </p>
                  <p className="font-18 font-wix-bold">{stat.heading}</p>
                  <p className="font-18 font-wix-regular">{stat.data}</p>
                </div>
              </motion.div>
            ))}
          </div>
          <div className={classes.belowText}>
            <p className="font-12 font-wix-regular">
              * Statistics from multiple sources
            </p>
          </div>
        </div>
      </section>
      <section className={classes.tabsSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 color-black1"
            >
              Real-world application of WhatsApp CRM
            </motion.h2>
            <div className={classes.tabsContainer}>
              <div
                className={clsx(
                  classes.tab,
                  "font-wix-medium",
                  "font-20",
                  activeRealWorld === 0 && "active"
                )}
                onClick={() => setActiveRealWorld(0)}
              >
                E-commerce
              </div>
              <div
                className={clsx(
                  classes.tab,
                  "font-wix-medium",
                  "font-20",
                  activeRealWorld === 1 && "active"
                )}
                onClick={() => setActiveRealWorld(1)}
              >
                Healthcare
              </div>
              <div
                className={clsx(
                  classes.tab,
                  "font-wix-medium",
                  "font-20",
                  activeRealWorld === 2 && "active"
                )}
                onClick={() => setActiveRealWorld(2)}
              >
                Education
              </div>
            </div>
          </div>
          <div className={classes.tabCard}>
            <div className={classes.tabCardLeftContainer}>
              {tabContentData.map(
                (listItem, index) =>
                  index === activeRealWorld && (
                    <div>
                      <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                        className="font-wix-regular font-18 color-black2"
                      >
                        {listItem.subtitle}
                      </motion.p>
                      <div className={classes.benfitListContainer}>
                        {listItem.tabContent.map((item) => (
                          <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3 }}
                            className={classes.benfitListItem}
                          >
                            <LazyImage
                              source={item.icon}
                              className={classes.benfitIcon}
                              alt={item.alt}
                            />
                            <div className={classes.benfitListItemContent}>
                              <p className="font-wix-semibold font-20 color-black1">
                                {item.heading}
                              </p>
                              <p className="font-wix-regular font-16 color-black2">
                                {item.content}
                              </p>
                            </div>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className={classes.tabCardRightContainer}>
              {tabContentData.map(
                (listItem, index) =>
                  index === activeRealWorld && (
                    <motion.div
                      initial={{ opacity: 1, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      // className={classes.aiSectionRight}
                    >
                      <LazyImage
                        source={listItem.image}
                        alt={listItem.alt}
                        width={"100%"}
                      />
                    </motion.div>
                  )
              )}
            </div>
          </div>
        </div>
      </section>
      <section className={classes.successSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 color-black1"
            >
              Customer success stories
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 color-black2"
            >
              Discover how businesses across the globe found solutions for their
              different business needs with Pepper Cloud WhatsApp CRM software.
            </motion.p>
          </div>
        </div>
        <Swiper
          slidesPerView="auto"
          spaceBetween={20}
          speed={2000}
          modules={[Autoplay]}
          allowTouchMove={true}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          className={classes.successStorySlider}
          breakpoints={{
            // For smaller screens (mobile)
            0: {
              freeMode: true,
              autoplay: false,
              allowTouchMove: true,
            },
            // For larger screens (desktop)
            768: {
              freeMode: false,
              autoplay: {
                delay: 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              },
              speed: 20000,
              loop: true,
              allowTouchMove: true,
            },
          }}
        >
          {successList.map((successStory) => (
            <SwiperSlide className={classes.successCard}>
              <div
                className={classes.successHeader}
                style={{
                  backgroundImage: `url(${successStory.backgroundImage})`,
                }}
              >
                <div
                  className={clsx(
                    classes.tag,
                    "font-wix-regular",
                    "font-14",
                    "font-white"
                  )}
                >
                  {successStory.tag}
                </div>
              </div>
              <div className={classes.successBody}>
                <p className="font-wix-regular color-black3 font-itallic">
                  {successStory.content}
                </p>
                <a
                  href={successStory.readMore}
                  target="_blank"
                  className="font-wix-regular color-teal1 font-16 font-itallic"
                >
                  Read more
                </a>
                <img src={successStory.companyLogo} alt={successStory.alt} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <div className={classes.wpFaq}>
        <FaqSection
          backgroundColor={"#FFFFFF"}
          heading={"Frequently asked questions - AI-powered WhatsApp CRM"}
          faqs={faqs}
        />
      </div>
      <section className={classes.commonPageFooterSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="font-wix-medium font-56 font-white"
            >
              Turn every chat into a business opportunity with WhatsApp CRM
            </motion.h2>
            <Link
              className="primaryButton font-20 font-wix-medium font-white"
              to="/contact?utm_source=WhatsApp+CRM&utm_medium=Get+started"
            >
              Get started
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};

export default WhatsAppNew;
